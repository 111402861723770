import React, { useState, useMemo, useCallback, useEffect } from "react";
import SortableTable from "../components/SortTable";
import { GridColDef } from "@mui/x-data-grid";
import SearchIcon from "@mui/icons-material/Search";
import AddToListsModal from "../components/AddToListsModal";
import {
  InputLabel,
  Select,
  Button,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  FormControl,
  MenuItem,
} from "@mui/material";
import { GridSelectionModel } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import {
  useRequestState,
  useSkillsList,
  useLists,
  useAddToList,
  useSearchTerm,
  useDisciplines,
  useDisciplineFilter,
  useLevels,
  useLevelFilter,
  usePageSize,
} from "../store/hooks";
import { createNewList, getListById } from "../utils";
import { ListItem, Skill } from "../types";

const columns: GridColDef[] = [
  {
    field: "id",
    hide: true,
  },
  {
    headerName: "Name",
    field: "name",
    filterable: false,
    width: 200,
    renderCell: (cellValues) => (
      <Link to={`/skill/${cellValues.id}`}>{cellValues?.row?.name}</Link>
    ),
  },
  {
    headerName: "Discipline",
    field: "discipline",
    sortable: true,
    filterable: false,
  },
  {
    headerName: "Level",
    field: "level",
    sortable: true,
    filterable: false,
  },
  {
    headerName: "Priority",
    field: "priority",
    sortable: true,
  },
  {
    headerName: "Aliases",
    field: "aliases",
    filterable: false,
    sortable: false,
  },
  {
    headerName: "Prereqs",
    field: "prereqs",
    sortable: false,
  },
  {
    headerName: "Tags",
    field: "tags",
    sortable: false,
  },
  {
    headerName: "Notes",
    field: "notes",
    sortable: false,
  },
];

const HomeScreen = () => {
  const { searchTerm, setSearchTerm } = useSearchTerm();
  const { disciplines, setDisciplines } = useDisciplines();
  const { skillsList } = useSkillsList();
  const { requestState } = useRequestState();
  const { lists, setLists } = useLists();
  const [selectedSkills, setSelectedSkills] = useState<GridSelectionModel>([]);
  const [showListsModal, setShowListModal] = useState(false);
  const { pageSize, setPageSize } = usePageSize();
  const { addToList } = useAddToList();
  const { disciplineFilter, setDisciplineFilter } = useDisciplineFilter();
  const { levels, setLevels } = useLevels();
  const { levelFilter, setLevelFilter } = useLevelFilter();

  const filteredSkills = useMemo(() => {
    const regex = new RegExp(`(${searchTerm})`, "i");
    const levelRegex = new RegExp(`(${levelFilter})`, "i");
    return skillsList.filter((skill): boolean => {
      const disciplineMatch =
        !disciplineFilter ||
        skill?.discipline?.toLowerCase() === disciplineFilter;
      const levelMatch = !levelFilter || !!skill?.level?.match(levelRegex);
      return (
        (skill?.name?.match(regex) !== null ||
          skill?.aliases?.match(regex) !== null ||
          skill?.tags?.match(regex) !== null) &&
        disciplineMatch &&
        levelMatch
      );
    });
  }, [searchTerm, skillsList, disciplineFilter, levelFilter]);

  const saveDisciplinesFromSkillsList = useCallback(
    (skills: Skill[]) => {
      const disciplines = skills
        .reduce((disciplinesList: string[], skill) => {
          if (skill?.discipline) {
            const discipline = skill.discipline.toLowerCase();
            if (!disciplinesList.includes(discipline)) {
              disciplinesList.push(discipline);
            }
          }
          return disciplinesList;
        }, [])
        .sort();
      setDisciplines(disciplines);
    },
    [setDisciplines]
  );

  const saveLevelsFromSkillsList = useCallback(
    (skills: Skill[]) => {
      const levels = skills
        .reduce((levelList: string[], skill) => {
          if (skill?.level) {
            const level = skill.level.toLowerCase();
            const split = level.split(", ");
            if (split.length === 1 && !levelList.includes(split[0])) {
              levelList.push(split[0]);
            }
          }
          return levelList;
        }, [])
        .sort();
      setLevels(levels);
    },
    [setLevels]
  );

  useEffect(() => {
    saveDisciplinesFromSkillsList(skillsList);
    saveLevelsFromSkillsList(skillsList);
  }, [skillsList, saveDisciplinesFromSkillsList, saveLevelsFromSkillsList]);

  const onCreateList = (listName: string) => {
    const newListsState = createNewList(lists, {
      id: "",
      name: listName,
      listItems: selectedSkills.map((skillId) => {
        return {
          id: skillId as string,
          type: "skill",
        };
      }),
    });
    setLists(newListsState);
    setShowListModal(false);
    setSelectedSkills([]);
  };

  const onAddToList = (listId: string) => {
    const list = getListById(lists, listId);
    if (list) {
      const listItems = selectedSkills.map((skillId) => {
        return {
          id: skillId as string,
          type: "skill",
        };
      });
      addToList(list, listItems as ListItem[]);
    }
    setShowListModal(false);
    setSelectedSkills([]);
  };

  return (
    <Grid container direction="column" className="homeScreen">
      <Grid item>
        <Typography variant="h3" component="h2">
          Aerial Skills
        </Typography>
      </Grid>
      <Grid
        item
        container
        alignItems="flex-end"
        justifyContent="space-between"
        alignContent="center"
        marginBottom="2rem"
      >
        <FormControl fullWidth>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            gap="2rem"
          >
            <Grid item xs={12} sm={8}>
              <TextField
                id="filter-text-field"
                label="Search by skill name, alias, or tags"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setSearchTerm(e.target.value)}
                disabled={requestState === "loading"}
                variant="outlined"
                autoComplete="off"
                margin="normal"
                value={searchTerm}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={2} order={{ xs: 4, sm: 2 }}>
              <Button
                variant="contained"
                disabled={!selectedSkills.length}
                onClick={() => setShowListModal(true)}
                fullWidth
              >{`Add Skill${
                selectedSkills.length > 1 ? "s" : ""
              } To List`}</Button>
            </Grid>

            <Grid item xs={12} sm={5} order={{ xs: 2, sm: 3 }}>
              <FormControl fullWidth>
                <InputLabel id="disciplineFilterLabel">
                  Filter By Discipline
                </InputLabel>
                <Select
                  labelId="disciplineFilterLabel"
                  id="disciplineFilter"
                  label="Select Discipline"
                  value={disciplineFilter}
                  onChange={(e) => setDisciplineFilter(e?.target?.value)}
                >
                  <MenuItem value="">Choose a discipline</MenuItem>
                  {disciplines.map((discipline) => (
                    <MenuItem key={discipline} value={discipline}>
                      {discipline}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={5} order={{ xs: 3, sm: 4 }}>
              <FormControl fullWidth>
                <InputLabel id="levelFilterLabel">Filter By Level</InputLabel>
                <Select
                  labelId="levelFilterLabel"
                  id="levelFilter"
                  label="Select Level"
                  value={levelFilter}
                  onChange={(e) => setLevelFilter(e?.target?.value)}
                >
                  <MenuItem value="">Choose a level</MenuItem>
                  {levels.map((level) => (
                    <MenuItem key={level} value={level}>
                      {level}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </FormControl>
      </Grid>

      <Grid item>
        <SortableTable
          skillsList={filteredSkills}
          columns={columns}
          loading={requestState === "loading"}
          onSelectionModelChange={(ids) => setSelectedSkills(ids)}
          pageSize={pageSize}
          selectionModel={selectedSkills}
          onPageSizeChange={(size) => setPageSize(size)}
        />
      </Grid>
      <AddToListsModal
        lists={lists}
        isOpen={showListsModal}
        onClose={() => setShowListModal(false)}
        onSubmitCreateList={(name) => onCreateList(name)}
        onSubmitAddToList={(listId) => onAddToList(listId)}
      />
    </Grid>
  );
};

export default HomeScreen;
