import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

interface ConfirmationDialogProps {
  onClickButton: (accepted: boolean) => void;
  isOpen: boolean;
  title: string;
  message: string;
  onClose: () => void;
}

const ConfirmationDialog = ({
  title,
  message,
  isOpen,
  onClickButton,
  onClose,
}: ConfirmationDialogProps) => (
  <Dialog
    open={isOpen}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    onClose={() => onClose()}
  >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {message}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => onClickButton(false)}>Cancel</Button>
      <Button onClick={() => onClickButton(true)}>Confirm</Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmationDialog;
