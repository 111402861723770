import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";

interface CreateListFormProps {
  id: string;
  textfieldLabel: string;
  buttonLabel: string;
  onSubmit: any;
  placeholder?: string;
  modalLayout?: boolean;
}

const CreateListForm = ({
  id,
  textfieldLabel,
  buttonLabel,
  onSubmit,
  placeholder,
  modalLayout = false,
}: CreateListFormProps) => {
  const [value, setValue] = useState("");

  const onSubmitHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    onSubmit(value);
    setValue("");
  };

  return (
    <FormControl id={id} fullWidth>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={modalLayout ? 12 : 8}>
          <TextField
            id={`${id}-textField`}
            label={textfieldLabel}
            onChange={(e) => setValue(e.target.value)}
            value={value}
            variant="outlined"
            autoComplete="off"
            fullWidth
            margin="normal"
            placeholder={placeholder}
          />
        </Grid>
        {!modalLayout ? <Grid item xs={0} sm={2}></Grid> : null}
        <Grid item xs={12} sm={modalLayout ? 12 : 2}>
          <Button
            variant="contained"
            type="submit"
            id={`${id}-button`}
            color="secondary"
            fullWidth
            onClick={(e) => onSubmitHandler(e)}
          >
            {buttonLabel}
          </Button>
        </Grid>
      </Grid>
    </FormControl>
  );
};

export default CreateListForm;
