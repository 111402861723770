import React, { useState, useMemo } from "react";
import { useLists, useCreateList, useDeleteList } from "../store/hooks";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import Tooltip from "@mui/material/Tooltip";
import ListsDisplay from "./ListsDisplay";
import CreateListForm from "./CreateListForm";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/material/styles";
import ConfirmationDialog from "./ConfirmationDialog";

const FixedFooter = styled(Box)(({ theme }) => ({
  position: "fixed",
  bottom: 0,
  width: "100%",
  padding: "1rem 2rem",
  backgroundColor: theme.palette.grey[200],
  borderTop: theme.palette.grey[400],
  zIndex: 2,
}));

interface ListsOverlayProps {
  className?: string;
}

const ListsOverlay = ({ className }: ListsOverlayProps) => {
  const { lists } = useLists();
  const [isOpen, setIsOpen] = useState(false);
  const [listIdToDelete, setListIdToDelete] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const { createList } = useCreateList();
  const { deleteList } = useDeleteList();
  const theme = useTheme();
  const listToDelete = useMemo(
    () => lists.find((list) => list.id === listIdToDelete),
    [listIdToDelete, lists]
  );

  /**
   * Toggle the overlay open or closed.
   */
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  /**
   * Handle submitting the create list form.
   * @param name
   */
  const onSubmitCreateList = (name: string) => {
    createList(name);
  };

  /**
   * Handle clicking the delete icon button.
   * @param id
   */
  const onClickDelete = (id: string) => {
    setListIdToDelete(id);
    setOpenModal(true);
  };

  /**
   * Handle confirmation or dismissal of "delete list" dialog.
   * @param accepted
   */
  const onClickDialogButton = (accepted: boolean) => {
    if (accepted) {
      deleteList(listIdToDelete);
    }
    setOpenModal(false);
  };

  /**
   * Remove id when dialog closes to avoid flash of text changing.
   */
  const onDialogClose = () => {
    setListIdToDelete("");
  };

  return (
    <FixedFooter className={className} theme={theme}>
      <Grid direction="column" container>
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h5" component="p">
              Skill Lists ({lists.length})
            </Typography>
          </Grid>
          <Grid item>
            {isOpen ? (
              <Tooltip title="Hide Lists">
                <IconButton onClick={() => toggleOpen()}>
                  <ExpandMore fontSize="large" />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Show Lists">
                <IconButton onClick={() => toggleOpen()}>
                  <ExpandLess fontSize="large" />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </Grid>
        <Grid item container>
          <Collapse in={isOpen} style={{ flex: "0 0 100%" }}>
            <ListsDisplay
              lists={lists}
              onClickDelete={(id) => onClickDelete(id)}
            />
            <Divider variant="fullWidth" style={{ marginBottom: "1.5rem" }} />
            <CreateListForm
              id="list-form-overlay"
              buttonLabel="Create List"
              onSubmit={(e: string) => onSubmitCreateList(e)}
              placeholder="Enter list name"
              textfieldLabel="Create a new list"
            />
          </Collapse>
        </Grid>
      </Grid>
      <ConfirmationDialog
        title={`Delete ${listToDelete?.name}`}
        message={`Do you want to delete ${listToDelete?.name}?`}
        isOpen={openModal}
        onClickButton={(accepted) => onClickDialogButton(accepted)}
        onClose={() => onDialogClose()}
      />
    </FixedFooter>
  );
};

export default ListsOverlay;
