import React from "react";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

const StyledHeader = styled("header")(({ theme }) => ({
  width: "100vw",
  maxHeight: "100px",
  backgroundColor: theme.palette.primary.main,
  padding: "1.5rem 2rem 0.5rem 2rem",
  alignItems: "center",
  marginBottom: "2rem",
}));

const InnerWrap = styled("div")`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;

const Logo = styled("img")`
  max-height: 60px;
`;

const H1 = styled("h1")`
  margin: 0;
`;

const Header = () => {
  const theme = useTheme();
  return (
    <StyledHeader className="siteHeader" theme={theme}>
      <InnerWrap>
        <Link to="/">
          <H1>
            <Logo src="/assets/sky-candy-logo.png" alt="Home" />
          </H1>
        </Link>
      </InnerWrap>
    </StyledHeader>
  );
};

export default Header;
