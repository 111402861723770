import React from "react";
import { DataGrid, GridColDef, GridSelectionModel } from "@mui/x-data-grid";
import { Skill } from "../types";

interface SortableTableProps {
  skillsList: Skill[];
  columns: GridColDef[];
  loading: boolean;
  onSelectionModelChange: (e: GridSelectionModel) => void;
  pageSize?: number;
  selectionModel: GridSelectionModel;
  onPageSizeChange: (e: number) => void;
}

const SortableTable = ({
  skillsList,
  columns,
  onSelectionModelChange,
  loading,
  pageSize,
  selectionModel,
  onPageSizeChange,
}: SortableTableProps) => {
  return (
    <div style={{ display: "flex", height: "100%" }}>
      <div style={{ flexGrow: 1 }}>
        <DataGrid
          autoHeight
          checkboxSelection
          rows={skillsList}
          columns={columns}
          onSelectionModelChange={(ids) => onSelectionModelChange(ids)}
          loading={loading}
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          selectionModel={selectionModel}
          onPageSizeChange={(e) => onPageSizeChange(e)}
        />
      </div>
    </div>
  );
};

export default SortableTable;
