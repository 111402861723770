import { Skill, Disciplines } from "../types";

enum RowMap {
  "name" = 0,
  "level" = 1,
  "priority" = 2,
  "discipline" = 3,
  "aliases" = 4,
  "prereqs" = 5,
  "tags" = 6,
  "notes" = 7,
  "image" = 10,
  "internalVideo" = 11,
  "externalVideo" = 12,
  "id" = 13,
}

const getSkills = async (): Promise<Skill[]> => {
  const results = await fetch("/data/skills.json", {
    method: "get",
    headers: {
      "content-type": "text/json",
    },
  })
    .then((response) => response.text())
    .then((text) => JSON.parse(text))
    .then((array) => array.slice(2))
    // Convert to Skill[]
    .then((resultsArray): Skill[] => {
      return resultsArray.map((row: any) => {
        return {
          id: row[RowMap["id"]],
          name: row[RowMap["name"]],
          level: row[RowMap["level"]],
          priority: row[RowMap["priority"]],
          discipline: row[RowMap["discipline"]] as Disciplines,
          aliases: row[RowMap["aliases"]],
          prereq: row[RowMap["prereqs"]],
          tags: row[RowMap["tags"]],
          notes: row[RowMap["notes"]],
          image: row[RowMap["image"]],
          video: row[RowMap["externalVideo"]] || row[RowMap["internalVideo"]],
        };
      });
    })
    // Remove skills without name or id
    .then((skillsArray): Skill[] =>
      skillsArray.filter((skill) => {
        return skill?.id?.length && skill?.name?.length;
      })
    )
    //Dedupe array.
    .then((skillsArray) => {
      const idList: string[] = [];
      return skillsArray.filter((skill: Skill) => {
        if (idList.includes(skill.id)) {
          return false;
        }
        idList.push(skill.id);
        return true;
      });
    })
    .catch((error) => {
      console.error(error);
      return [] as Skill[];
    });

  return results;
};

export { getSkills };
