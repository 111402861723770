import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import BreadCrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { useSkillsList } from "../store/hooks";
import { getSkillById, getListById } from "../utils";
import { useLists, useAddToList } from "../store/hooks";
import Grid from "@mui/material/Grid";
import SelectListDropdown from "../components/SelectListDropdown";
import { List, ListItem } from "../types";
import Divider from "@mui/material/Divider";
import Video from "../components/Video";

const SkillScreen = () => {
  const { skillId } = useParams();
  const { skillsList } = useSkillsList();
  const { lists } = useLists();
  const { addToList } = useAddToList();

  const skill = useMemo(() => {
    return getSkillById(skillsList, skillId as string);
  }, [skillsList, skillId]);

  const selectListDropdownSubmitCallback = (listId: string) => {
    if (!skill) return; // Early return to stop Typescript from complaining.
    const targetList = getListById(lists, listId);
    const listItem: ListItem = { id: skill.id, type: "skill" };
    addToList(targetList as List, [listItem]);
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      className="skillScreen"
      style={{ minHeight: "100%", flex: 1 }}
    >
      <Grid item marginBottom="2rem">
        <BreadCrumbs aria-label="breadcrumb" style={{ marginBottom: "1rem" }}>
          <Typography variant="body2">
            <Link to="/">Skills Table</Link>
          </Typography>
          <Typography variant="body2">{skill?.name}</Typography>
        </BreadCrumbs>
        <Typography
          className="skillName"
          variant="h3"
          component="h2"
          marginBottom="1.5rem"
        >
          {skill?.name}
        </Typography>
        {skill?.image ? <img src={skill?.image} alt={skill?.name} /> : null}
        {skill?.video ? <Video url={skill.video} title={skill.name} /> : null}
        {skill?.aliases ? (
          <Typography className="skillAliases" variant="body1" component="p">
            <strong>Alias:</strong> {skill?.aliases}
          </Typography>
        ) : null}
        <Typography className="skillDiscipline" variant="body1" component="p">
          <strong>Discipline:</strong>{" "}
          {skill?.discipline ? skill?.discipline : "?"}
        </Typography>
        <Typography className="skillLevel" variant="body1" component="p">
          <strong>Level:</strong> {skill?.level ? skill.level : "?"}
        </Typography>
        <Typography className="skillPriority" variant="body1" component="p">
          <strong>Priority:</strong> {skill?.priority ? skill.priority : "?"}
        </Typography>
        <Typography className="skillPrereqs" variant="body1" component="p">
          <strong>Prereqs:</strong> {skill?.prereq ? skill.prereq : "none"}
        </Typography>
        <Typography className="skillNotes" variant="body1" component="p">
          <strong>Notes:</strong> {skill?.notes ? skill.notes : "none"}
        </Typography>
        <Typography className="skillTags" variant="body1" component="p">
          <strong>Tags:</strong> {skill?.tags ? skill.tags : "none"}
        </Typography>
      </Grid>
      <Grid style={{ marginTop: "auto" }} item>
        <Divider variant="fullWidth" style={{ marginBottom: "1rem" }} />
        <Typography variant="body2" marginBottom=".5rem">
          Add {skill?.name} to a list
        </Typography>
        <SelectListDropdown
          lists={lists}
          id="add-to-list-modal-select"
          label="Choose a list"
          onSubmit={(e) => selectListDropdownSubmitCallback(e)}
          onChange={(_e) => {}}
          showCreateListOption={false}
          displayButton={true}
          buttonLabel="Add to list"
        />
      </Grid>
    </Grid>
  );
};

export default SkillScreen;
