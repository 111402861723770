import { atom } from "recoil";
import { Skill, List, ToastMessage } from "../types";

interface localStoreEffectProps {
  setSelf: any;
  onSet: any;
}

const localStorageEffect =
  (key: string) =>
  ({ setSelf, onSet }: localStoreEffectProps) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue: any, _: any, isReset: boolean) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };

const sessionStorageEffect =
  (key: string) =>
  ({ setSelf, onSet }: localStoreEffectProps) => {
    const savedValue = sessionStorage.getItem(key);
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue: any, _: any, isReset: boolean) => {
      isReset
        ? sessionStorage.removeItem(key)
        : sessionStorage.setItem(key, JSON.stringify(newValue));
    });
  };

export const skillsListAtom = atom<Skill[]>({
  key: "skillsList",
  default: [],
});

export const requestStateAtom = atom<"idle" | "error" | "loading">({
  key: "requestState",
  default: "idle",
});

export const listsAtom = atom<List[]>({
  key: "lists",
  default: [],
  effects: [localStorageEffect("recoil_lists_state")],
});

export const toastAtom = atom<ToastMessage | null>({
  key: "toastMessage",
  default: null,
});

export const searchTermAtom = atom<string>({
  key: "searchTerm",
  default: "",
});

export const disciplinesAtom = atom<string[]>({
  key: "disciplines",
  default: [],
});

export const disciplineFilterAtom = atom<string>({
  key: "disciplineFilter",
  default: "",
});

export const levelsAtom = atom<string[]>({
  key: "levels",
  default: [],
});

export const levelFilterAtom = atom<string>({
  key: "levelFilter",
  default: "",
});

export const pageSizeAtom = atom<number>({
  key: "pageSize",
  default: 50,
});

export const hasAccessAtom = atom<boolean>({
  key: "hasAccess",
  default: false,
  effects: [sessionStorageEffect("recoil_state_has_access")],
});
