import React, { useMemo, useState } from "react";
import BreadCrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import {
  useLists,
  useReorderList,
  useAddToList,
  useDeleteFromList,
  useSkillsList,
} from "../store/hooks";
import { getListById, getSkillById } from "../utils";
import ListItemsListDisplay from "../components/ListItemsListDisplay";
import { DropResult } from "react-beautiful-dnd";
import SelectListDropdown from "../components/SelectListDropdown";
import { List, ListItem } from "../types";
import ConfirmationDialog from "../components/ConfirmationDialog";
import Divider from "@mui/material/Divider";

const ListScreen = () => {
  const { skillsList } = useSkillsList();
  const { listId } = useParams();
  const { lists } = useLists();
  const { reorderList } = useReorderList();
  const { addToList } = useAddToList();
  const { deleteItemFromList } = useDeleteFromList();
  const [itemIdToDelete, setItemIdToDelete] = useState("");
  const [openDialog, setOpenDialog] = useState(false);

  const list = useMemo(() => {
    return getListById(lists, listId as string);
  }, [lists, listId]);

  const listItemToDelete = useMemo(() => {
    if (!list || !itemIdToDelete) return;
    const listItem = list.listItems.find(
      (listItem) => listItem.id === itemIdToDelete
    );
    if (!listItem) return;
    if (listItem.type === "skill") {
      return getSkillById(skillsList, listItem.id);
    }
    if (listItem.type === "list") {
      return getListById(lists, listItem.id);
    }
  }, [list, itemIdToDelete, lists, skillsList]);

  const onDragEnd = (result: DropResult) => {
    if (list) {
      reorderList(result, list);
    }
  };

  const onClickDelete = (id: string) => {
    setItemIdToDelete(id);
    setOpenDialog(true);
  };

  const onClickDialogButton = (accepted: boolean) => {
    if (accepted) {
      deleteItemFromList(list as List, itemIdToDelete);
    }
    setOpenDialog(false);
  };

  const selectListDropdownSubmitCallback = (listId: string) => {
    if (!list) return; // Early return to stop Typescript from complaining.
    const targetList = getListById(lists, listId);
    const listItem: ListItem = { id: list.id, type: "list" };
    addToList(targetList as List, [listItem]);
  };

  const selectListDropdownChangeCallback = (e: string) => {};

  const onCloseDialog = () => {
    setItemIdToDelete("");
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      className="listScreen"
      style={{ minHeight: "100%", flex: 1 }}
    >
      {list ? (
        <>
          <Grid item>
            <BreadCrumbs
              aria-label="breadcrumb"
              style={{ marginBottom: "1rem" }}
            >
              <Typography variant="body2">
                <Link to="/">Skills Table</Link>
              </Typography>
              <Typography variant="body2">{list.name}</Typography>
            </BreadCrumbs>
            <Typography variant="h3" component="h2">
              {list.name}
            </Typography>
            {list.listItems.length ? (
              <ListItemsListDisplay
                listItems={list.listItems}
                onDragEnd={(e) => onDragEnd(e)}
                onClickDelete={(e) => onClickDelete(e)}
              />
            ) : (
              <Typography variant="overline">Nothing added to list!</Typography>
            )}
          </Grid>
          <Grid item>
            <Divider variant="fullWidth" style={{ marginBottom: "1rem" }} />
            <Typography variant="body2" marginBottom=".5rem">
              Add {list.name} to another list
            </Typography>
            <SelectListDropdown
              lists={lists.filter((item) => item.id !== list.id)}
              id="add-to-list-modal-select"
              label="Choose a list"
              onSubmit={(e) => selectListDropdownSubmitCallback(e)}
              onChange={(e) => selectListDropdownChangeCallback(e)}
              showCreateListOption={false}
              displayButton={true}
              buttonLabel="Add to list"
            />
            {listItemToDelete ? (
              <ConfirmationDialog
                title="Delete from list"
                message={`Do you want to delete ${listItemToDelete.name} from ${list.name}?`}
                isOpen={openDialog}
                onClickButton={(accepted) => onClickDialogButton(accepted)}
                onClose={() => onCloseDialog()}
              />
            ) : null}
          </Grid>
        </>
      ) : null}
    </Grid>
  );
};

export default ListScreen;
