const theme = {
  palette: {
    primary: {
      main: "#540104",
      contrastText: "#e7d5ba",
    },
    secondary: {
      main: "#00181c",
      contrastText: "#e7d5ba",
    },
    canvas: {
      main: "#e7d5ba",
    },
    alternate: {
      main: "#58d2db",
    },
  },
};

export default theme;
