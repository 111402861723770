import React, { useState } from "react";
import { Backdrop, Box, Button, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledBackdrop = styled(Backdrop)`
  backdrop-filter: blur(10px);
  z-index: 100;
`;
interface PasswordOverlayProps {
  isOpen: boolean;
  password: string;
  onCorrect: () => void;
}

const PasswordOverlay = ({
  isOpen,
  password,
  onCorrect,
}: PasswordOverlayProps) => {
  const [showError, setShowError] = useState(false);

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      enteredPassword: { value: string };
    };
    const enteredPassword = target.enteredPassword.value;
    if (enteredPassword === password) {
      onCorrect();
    } else {
      setShowError(true);
    }
  };

  return (
    <StyledBackdrop open={isOpen}>
      {" "}
      <Box
        sx={{
          backgroundColor: "#fff",
          width: 400,
          padding: 3,
          borderRadius: 2,
          textAlign: "center",
        }}
      >
        <Typography variant="h5" gutterBottom>
          Enter Password
        </Typography>
        <form onSubmit={(e) => handleSubmit(e)}>
          <TextField
            label="Password"
            type="password"
            name="enteredPassword"
            fullWidth
            required
            error={showError}
            helperText={showError ? "Wrong password. Try again." : ""}
          />

          <Button
            variant="contained"
            color="primary"
            type="submit"
            sx={{ mt: 2 }}
          >
            Submit
          </Button>
        </form>
      </Box>
    </StyledBackdrop>
  );
};

export default PasswordOverlay;
