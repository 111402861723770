import React, { useMemo } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { Link } from "react-router-dom";
import { ListItem as iListItem } from "../types";
import { getSkillById, getListById } from "../utils";
import { useSkillsList, useLists } from "../store/hooks";
import Tooltip from "@mui/material/Tooltip";

interface ListItemsListDisplayProps {
  listItems: iListItem[];
  onDragEnd: (result: DropResult) => void;
  onClickDelete: (e: string) => void;
}

const ListItemsListDisplay = ({
  listItems,
  onDragEnd,
  onClickDelete,
}: ListItemsListDisplayProps) => {
  const { skillsList } = useSkillsList();
  const { lists } = useLists();

  const expandedListItems = useMemo(() => {
    return listItems
      .map((listItem) => {
        let item = { type: listItem.type };
        if (listItem.type === "skill") {
          return { ...getSkillById(skillsList, listItem.id), ...item };
        }
        // Otherwise it's a list.
        return { ...getListById(lists, listItem.id), ...item };
      })
      .filter((item) => !!item); // Because Typescript complains about listItems being possibly undefined.
  }, [listItems, skillsList, lists]);

  return (
    <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
      <Droppable droppableId="skills-list-droppable">
        {(provided) => (
          <List
            className="listItemsListDisplay"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {expandedListItems.map((listItem, index) => {
              return (
                <Draggable
                  key={listItem.id}
                  draggableId={listItem.id as string}
                  index={index}
                >
                  {(provided) => (
                    <ListItem
                      key={listItem.id}
                      {...provided.draggableProps}
                      ref={provided.innerRef}
                      disableGutters
                    >
                      <ListItemIcon {...provided.dragHandleProps}>
                        <Tooltip title="Drag to reorder">
                          <IconButton aria-label="Drag to reorder">
                            <DragHandleIcon />
                          </IconButton>
                        </Tooltip>
                      </ListItemIcon>
                      <ListItemText>
                        <Link to={`/${listItem.type}/${listItem.id}`}>
                          {listItem.name}
                        </Link>
                      </ListItemText>
                      <ListItemIcon>
                        <Tooltip title={`Delete ${listItem.name}`}>
                          <IconButton
                            aria-label={`Delete ${listItem.name}`}
                            onClick={() => onClickDelete(listItem.id as string)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </ListItemIcon>
                    </ListItem>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ListItemsListDisplay;
