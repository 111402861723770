import sha256 from "crypto-js/sha256";
import encHex from "crypto-js/enc-hex";
import { List, ListItem, Skill } from "./types";

export const parseCSV = (str: string) => {
  var arr: Array<Array<string>> = [];
  var quote = false; // 'true' means we're inside a quoted field

  // Iterate over each character, keep track of current row and column (of the returned array)
  for (var row = 0, col = 0, c = 0; c < str.length; c++) {
    var cc = str[c],
      nc = str[c + 1]; // Current character, next character
    arr[row] = arr[row] || []; // Create a new row if necessary
    arr[row][col] = arr[row][col] || ""; // Create a new column (start with empty string) if necessary

    // If the current character is a quotation mark, and we're inside a
    // quoted field, and the next character is also a quotation mark,
    // add a quotation mark to the current column and skip the next character
    if (cc === '"' && quote && nc === '"') {
      arr[row][col] += cc;
      ++c;
      continue;
    }

    // If it's just one quotation mark, begin/end quoted field
    if (cc === '"') {
      quote = !quote;
      continue;
    }

    // If it's a comma and we're not in a quoted field, move on to the next column
    if (cc === "," && !quote) {
      ++col;
      continue;
    }

    // If it's a newline (CRLF) and we're not in a quoted field, skip the next character
    // and move on to the next row and move to column 0 of that new row
    if (cc === "\r" && nc === "\n" && !quote) {
      ++row;
      col = 0;
      ++c;
      continue;
    }

    // If it's a newline (LF or CR) and we're not in a quoted field,
    // move on to the next row and move to column 0 of that new row
    if (cc === "\n" && !quote) {
      ++row;
      col = 0;
      continue;
    }
    if (cc === "\r" && !quote) {
      ++row;
      col = 0;
      continue;
    }

    // Otherwise, append the current character to the current column
    arr[row][col] += cc;
  }
  return arr;
};

/**
 * Hash a string to create a unique identifier from source string.
 * @param input
 * @returns
 */
export const hashString = (input: string): string => {
  const hash = sha256(input);
  return hash.toString(encHex);
};

/**
 * Create new list of lists from current list state and an additional list.
 * @param currentList List array
 * @param addition List
 * @returns List Array
 */
export const createNewList = (currentList: List[], addition: List): List[] => {
  if (!addition.id) {
    addition.id = hashString(addition.name);
  }
  const newList = [...currentList, addition];
  return newList.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
};

/**
 * Find and return List by id.
 * @param lists
 * @param id
 * @returns
 */
export const getListById = (lists: List[], id: string): List | undefined =>
  lists.find((list) => list.id === id);

/**
 * Find and return skill by id.
 * @param skills
 * @param skillId
 * @returns
 */
export const getSkillById = (
  skills: Skill[],
  skillId: string
): Skill | undefined => skills.find((skill) => skill.id === skillId);

/**
 * Check if list contains and item with given id.
 * @param lists
 * @param id
 * @returns
 */
export const listContainsListItem = (
  listItems: ListItem[],
  id: string
): boolean => listItems.findIndex((listItem) => listItem.id === id) > -1;

/**
 * Rorder array by shifting item from start index to end index.
 * @param list
 * @param startIndex
 * @param endIndex
 * @returns
 */
export const reorderArray = (
  list: any[],
  startIndex: number,
  endIndex: number
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
