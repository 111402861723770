import { Snackbar, Alert } from "@mui/material";
import { useToast } from "../store/hooks";

const ToastManager = () => {
  const { toast, setToast } = useToast();

  const handleClose = () => {
    setToast(null);
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={!!toast}
      autoHideDuration={6000}
      onClose={handleClose}
      onClick={() => handleClose()}
    >
      <Alert severity={toast?.severity} sx={{ width: "100%" }}>
        {toast?.message}
      </Alert>
    </Snackbar>
  );
};

export default ToastManager;
