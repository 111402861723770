import { useRecoilState } from "recoil";
import {
  skillsListAtom,
  requestStateAtom,
  listsAtom,
  toastAtom,
  searchTermAtom,
  disciplinesAtom,
  disciplineFilterAtom,
  levelsAtom,
  levelFilterAtom,
  pageSizeAtom,
  hasAccessAtom,
} from "./atoms";
import { List, ListItem } from "../types";
import { hashString, reorderArray, listContainsListItem } from "../utils";
import { DropResult } from "react-beautiful-dnd";

/**
 * Use skills.
 * @returns array of skills, setter for array of skills
 */
export const useSkillsList = () => {
  const [skillsList, setSkillsList] = useRecoilState(skillsListAtom);
  return { skillsList, setSkillsList };
};

/**
 * Use  request state.
 * @returns request state, setter for request state
 */
export const useRequestState = () => {
  const [requestState, setRequestState] = useRecoilState(requestStateAtom);
  return { requestState, setRequestState };
};

/**
 * Use lists
 * @returns lists, setter for lists
 */
export const useLists = () => {
  const [lists, setLists] = useRecoilState(listsAtom);
  return { lists, setLists };
};

/**
 * Use Toast messages.
 * @returns toast message object, setter for toast message
 */
export const useToast = () => {
  const [toast, setToast] = useRecoilState(toastAtom);
  return { toast, setToast };
};

export const useSearchTerm = () => {
  const [searchTerm, setSearchTerm] = useRecoilState(searchTermAtom);
  return { searchTerm, setSearchTerm };
};

export const useCreateList = () => {
  const { lists, setLists } = useLists();
  const { setToast } = useToast();
  const createList = (listName: string, listItems?: ListItem[]) => {
    if (lists.findIndex((list) => list.name === listName) > -1) {
      setToast({
        message: `${listName} already exists!`,
        severity: "warning",
      });
      return;
    }
    const newListState = [
      ...lists,
      {
        id: hashString(listName),
        name: listName,
        type: "list",
        listItems: listItems || [],
      },
    ];
    setLists(
      newListState.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })
    );
    setToast({ message: `${listName} created!`, severity: "success" });
  };
  return { createList };
};

export const useUpdateList = () => {
  const { lists, setLists } = useLists();
  const updateList = (list: List) => {
    setLists(
      lists.map((listItem) => {
        if (listItem.id === list.id) {
          return list;
        }
        return listItem;
      })
    );
  };
  return { updateList };
};

export const useReorderList = () => {
  const { updateList } = useUpdateList();
  const reorderList = (result: DropResult, list: List) => {
    if (!result.destination) {
      return;
    }

    const reorderedListItems = reorderArray(
      list.listItems,
      result.source.index,
      result.destination.index
    );

    updateList({
      ...list,
      listItems: [...reorderedListItems],
    });
  };
  return { reorderList };
};

export const useAddToList = () => {
  const { updateList } = useUpdateList();
  const { setToast } = useToast();
  const addToList = (list: List, listItems: ListItem[]) => {
    const cloneList = Object.assign({}, list);
    let message = "";
    const filteredItems = listItems.filter(
      (listItem) => !listContainsListItem(cloneList.listItems, listItem.id)
    );
    cloneList.listItems = [...cloneList.listItems, ...filteredItems];
    updateList(cloneList);
    const len = filteredItems.length;
    filteredItems.length
      ? (message = `${len} item${len > 1 ? "s" : ""} added to list!`)
      : (message = "0 items added to list. Are they already in the list?");
    setToast({
      message: message,
      severity: len ? "success" : "warning",
    });
  };
  return { addToList };
};

export const useDeleteFromList = () => {
  const { updateList } = useUpdateList();
  const { setToast } = useToast();
  const deleteItemFromList = (list: List, listItemId: string) => {
    const cloneList = Object.assign({}, list);
    cloneList.listItems = cloneList.listItems.filter(
      (listItem) => listItem.id !== listItemId
    );
    updateList(cloneList);
    setToast({ message: "Item deleted!", severity: "success" });
  };
  return { deleteItemFromList };
};

export const useDeleteList = () => {
  const { lists, setLists } = useLists();
  const { setToast } = useToast();
  const deleteList = (id: string) => {
    const deletedList = lists.find((list) => list.id === id);
    setLists(lists.filter((list) => list.id !== id));
    setToast({ message: `${deletedList?.name} deleted!`, severity: "success" });
  };
  return { deleteList };
};

export const useDisciplines = () => {
  const [disciplines, setDisciplines] = useRecoilState(disciplinesAtom);
  return { disciplines, setDisciplines };
};

export const useDisciplineFilter = () => {
  const [disciplineFilter, setDisciplineFilter] =
    useRecoilState(disciplineFilterAtom);
  return { disciplineFilter, setDisciplineFilter };
};

export const useLevels = () => {
  const [levels, setLevels] = useRecoilState(levelsAtom);
  return { levels, setLevels };
};

export const useLevelFilter = () => {
  const [levelFilter, setLevelFilter] = useRecoilState(levelFilterAtom);
  return { levelFilter, setLevelFilter };
};

export const usePageSize = () => {
  const [pageSize, setPageSize] = useRecoilState(pageSizeAtom);
  return { pageSize, setPageSize };
};

export const useHasAccess = () => {
  const [hasAccess, setHasAccess] = useRecoilState(hasAccessAtom);
  return { hasAccess, setHasAccess };
};
