import React, { useEffect } from "react";
import { getSkills } from "./api/getSkills";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomeScreen from "./screens/Home";
import ListScreen from "./screens/List";
import SkillScreen from "./screens/Skill";
import customTheme from "./theme";
import Header from "./components/Header";
import { useRequestState, useSkillsList, useHasAccess } from "./store/hooks";
import ListsOverlay from "./components/ListsOverlay";
import PasswordOverlay from "./components/PasswordOverlay";
import { styled } from "@mui/material/styles";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import ToastManager from "./components/ToastManager";

const MainWrap = styled("main")`
  padding: 0 2rem 8rem 2rem;
  display: flex;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;

const AppWrap = styled("div")`
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

function App() {
  const { setRequestState } = useRequestState();
  const { setSkillsList } = useSkillsList();
  const { hasAccess, setHasAccess } = useHasAccess();
  let theme = createTheme(customTheme);
  theme = responsiveFontSizes(theme);

  useEffect(() => {
    setRequestState("loading");
    getSkills()
      .then((skills) => {
        setRequestState("idle");
        setSkillsList(skills);
      })
      .catch((e) => {
        setRequestState("error");
        console.error(e);
      });
  }, [setRequestState, setSkillsList]);

  const onCorrectPassword = () => {
    setHasAccess(true);
  };

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline>
          <AppWrap className="app">
            <PasswordOverlay
              isOpen={!hasAccess}
              password="SpringdaleStarDrop"
              onCorrect={onCorrectPassword}
            />
            <Header />
            <MainWrap>
              <Routes>
                <Route index element={<HomeScreen />} />
                <Route path="list/:listId" element={<ListScreen />} />
                <Route path="skill/:skillId" element={<SkillScreen />} />
              </Routes>
            </MainWrap>
            <ListsOverlay className="listsOverlay" />
          </AppWrap>
          <ToastManager />
        </CssBaseline>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
