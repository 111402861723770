import React, { useState } from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { List } from "../types";
import Grid from "@mui/material/Grid";

interface SelectListDropdownProps {
  lists: List[];
  onChange: (e: string) => void;
  onSubmit: (e: string) => void;
  id: string;
  label: string;
  showCreateListOption?: boolean;
  displayButton?: boolean;
  buttonLabel: string;
  placeholder?: string;
  modalLayout?: boolean;
}

const createListKey = "createNewList";

const SelectListDropdown = ({
  lists,
  id,
  label,
  onChange,
  onSubmit,
  showCreateListOption,
  displayButton,
  buttonLabel,
  modalLayout = false,
}: SelectListDropdownProps) => {
  const [selectedList, setSelectedList] = useState("");

  const onSubmitHandler = () => {
    onSubmit(selectedList);
    setSelectedList("");
  };

  const onChangeHandler = (e: SelectChangeEvent<string>) => {
    e.preventDefault();
    onChange(e.target.value);
    setSelectedList(e.target.value);
  };

  return (
    <FormControl fullWidth>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        rowSpacing={1}
      >
        <Grid item xs={12} sm={modalLayout ? 12 : 8}>
          <InputLabel id={`${id}-inputLabel`}>{label}</InputLabel>
          <Select
            id={`${id}-select`}
            labelId={`${id}-inputLabel`}
            label={label}
            onChange={(e: SelectChangeEvent<string>) => onChangeHandler(e)}
            value={selectedList}
            fullWidth
          >
            {lists.map((list) => (
              <MenuItem key={list.id} value={list.id}>
                {list.name}
              </MenuItem>
            ))}
            {showCreateListOption ? (
              <MenuItem key={createListKey} value={createListKey}>
                Create New List
              </MenuItem>
            ) : null}
          </Select>
        </Grid>
        {displayButton ? (
          <Grid item xs={12} sm={modalLayout ? 12 : 2}>
            <Button
              type="submit"
              disabled={!selectedList}
              onClick={() => onSubmitHandler()}
              value={selectedList}
              variant="contained"
              fullWidth
            >
              {buttonLabel}
            </Button>
          </Grid>
        ) : null}
      </Grid>
    </FormControl>
  );
};

export default SelectListDropdown;
