import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CreateListForm from "../components/CreateListForm";
import SelectListDropdown from "./SelectListDropdown";
import { List } from "../types";

interface AddToListsModalProps {
  lists: List[];
  isOpen: boolean;
  onClose: () => void;
  onSubmitCreateList: (name: string) => void;
  onSubmitAddToList: (id: string) => void;
}

type ModalStates = "noLists" | "addToList" | "createNewList";

const AddToListsModal = ({
  lists,
  isOpen,
  onClose,
  onSubmitCreateList,
  onSubmitAddToList,
}: AddToListsModalProps) => {
  const [showCreateListForm, setShowCreateListForm] = useState<boolean>(false);
  const [modalState, setModalState] = useState<ModalStates>("noLists");

  useEffect(() => {
    if (!lists.length) {
      setModalState("noLists");
    }
    if (lists.length && showCreateListForm) {
      setModalState("createNewList");
    }
    if (lists.length && !showCreateListForm) {
      setModalState("addToList");
    }
  }, [showCreateListForm, lists]);

  const selectListDropdownSubmitCallback = (e: string): void => {
    if (e === "createNewList") {
      setShowCreateListForm(true);
    } else {
      setShowCreateListForm(false);
      onSubmitAddToList(e);
    }
  };

  const selectListDropdownChangeCallback = (e: string): void => {
    if (e === "createNewList") {
      setShowCreateListForm(true);
    } else {
      setShowCreateListForm(false);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => onClose()}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px", // Set your width here
          },
        },
      }}
    >
      <DialogTitle>
        {lists.length === 0 ? "Create a list" : "Add To List"}
      </DialogTitle>
      <DialogContent style={{ paddingTop: "20px" }}>
        {modalState === "noLists" ? (
          <CreateListForm
            id="create-list"
            textfieldLabel="Enter list name"
            buttonLabel="Create List"
            onSubmit={(e: string) => onSubmitCreateList(e)}
          />
        ) : (
          <>
            <SelectListDropdown
              lists={lists}
              id="add-to-list-modal-select"
              label="Add Skills To List"
              onSubmit={(e) => selectListDropdownSubmitCallback(e)}
              onChange={(e) => selectListDropdownChangeCallback(e)}
              showCreateListOption={true}
              displayButton={modalState !== "createNewList"}
              modalLayout={true}
              buttonLabel={
                modalState === "createNewList"
                  ? "Create New List"
                  : "Add To List"
              }
            />
            {modalState === "createNewList" ? (
              <CreateListForm
                id="create-list"
                textfieldLabel="Enter list name"
                buttonLabel="Create List"
                onSubmit={(e: string) => onSubmitCreateList(e)}
                modalLayout={true}
              />
            ) : null}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AddToListsModal;
