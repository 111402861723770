import React from "react";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

interface VideoProps {
  url: string;
  title: string;
}

const VideoContainer = styled("iframe")`
  aspect-ratio: 16/9;
  width: 100%;
  &.short {
    aspect-ratio: 9/16;
    max-width: 400px;
    margin: 0 auto;
    display: block;
  }
`;

const getVideoId = (url: string, isShort: boolean): string => {
  if (isShort) {
    return url.split("shorts/")[1];
  }
  return url.split("v=")[1];
};

const Video = ({ url, title }: VideoProps) => {
  const isEmbeddable = url.includes("youtube");
  const isShort = url.includes("shorts");
  const videoId = getVideoId(url, isShort);

  return isEmbeddable ? (
    <div>
      <VideoContainer
        src={`https://www.youtube.com/embed/${videoId}`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
        className={`youtube-video ${isShort ? "short" : ""}`}
      ></VideoContainer>
    </div>
  ) : (
    <Typography className="skillVideo" variant="body1" component="p">
      <strong>Video:</strong>{" "}
      <a target="_blank" rel="noreferrer" href={url}>
        {title} video
      </a>
    </Typography>
  );
};

export default Video;
