import React from "react";
import { List as iList } from "../types";
import {
  List,
  ListItem,
  ListItemText,
  IconButton,
  Tooltip,
  ListItemIcon,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";

interface ListsDisplayProps {
  lists: iList[];
  onClickDelete: (id: string) => void;
}

const ListsDisplay = ({ lists, onClickDelete }: ListsDisplayProps) => {
  return (
    <List className="listsDisplay" sx={{ width: "100%" }}>
      {lists.map((list) => {
        return (
          <ListItem
            disableGutters
            key={list.id}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Link to={`/list/${list.id}`}>
              <ListItemText>
                {list.name} ({list.listItems.length})
              </ListItemText>
            </Link>
            <ListItemIcon>
              <Tooltip title={`Delete ${list.name}`}>
                <IconButton
                  aria-label={`Delete ${list.name}`}
                  onClick={() => onClickDelete(list.id as string)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </ListItemIcon>
          </ListItem>
        );
      })}
    </List>
  );
};

export default ListsDisplay;
